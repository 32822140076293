<template>
  <section>
    <div class="breadcrumb">
      <Breadcrumb :pages="pages" />
    </div>
    <div>
      <EndossoSteps class="mt-5 mb-5 steps" :steps="steps" />
    </div>
  </section>
</template>

<script>
import Breadcrumb from "@/components/onpoint-breadcrumb/index.vue";
import EndossoSteps from "@/components/endosso/endosso-steps/EndossoSteps.vue";

import endossoProvider from "@/providers/endosso-provider.js";
import { mapMutations, mapActions } from "vuex";

export default {
  name: "endosso-cancelamento-new",
  components: {
    Breadcrumb,
    EndossoSteps,
  },
  data() {
    return {
      pages: [
        { label: "Propostas", to: "/propostas" },
        { label: "Endosso de cancelamento" },
      ],
      steps: [
        {
          label: "Informações do endosso",
          to:
            "/propostas/endosso-new/cancelamento/" +
            this.$route.params.policyUniqueId +
            "/" +
            this.$route.params.typeId +
            "/informacoes-do-endosso",
        },
        {
          label: "Motivo e documentos",
          to:
            "/propostas/endosso-new/cancelamento/" +
            this.$route.params.policyUniqueId +
            "/" +
            this.$route.params.typeId +
            "/motivo-e-documentos",
        },
        {
          label: "Resumo de alteração",
          to:
            "/propostas/endosso-new/cancelamento/" +
            this.$route.params.policyUniqueId +
            "/" +
            this.$route.params.typeId +
            "/resumo-da-alteracao",
        },
        {
          label: "Informações de pagamento",
          to:
            "/propostas/endosso-new/cancelamento/" +
            this.$route.params.policyUniqueId +
            "/" +
            this.$route.params.typeId +
            "/informacoes-de-pagamento",
        },
      ],
    };
  },
  computed: {},
  methods: {
    ...mapMutations("endosso-module-new", [
      "updateApoliceOriginalEndosso",
      "updateParametrosEndossoCancelamento",
    ]),
    ...mapActions("endosso-module-new", [""]),

    async buscarDadosApoliceOriginalEndossoCancelamento() {
      let payload = {
        PolicyUniqueId: this.$route.params.policyUniqueId,
        SubTipoEndossId: this.$route.params.subTipoEndossoId,
      };

      await this.$onpoint.loading(async () => {
        try {
          const [dadosApoliceOriginalEndosso, parametrosEndossoCancelamento] =
            await Promise.all([
              endossoProvider.obterDadosEndossoCancelamento(payload),
              endossoProvider.obterParametrosEndossoCancelamento(payload.PolicyUniqueId),
            ]);

          this.updateApoliceOriginalEndosso(dadosApoliceOriginalEndosso);
          this.updateParametrosEndossoCancelamento(parametrosEndossoCancelamento);
        } catch (errors) {
          const errorMessage = errors.response
            ? errors.response.data.Errors
            : "Erro ao buscar dados do endosso de cancelamento";
          this.$onpoint.errorModal(errorMessage);
        }
      });
    },
  },
  async created() {
    await this.buscarDadosApoliceOriginalEndossoCancelamento();
  },
};
</script>

<style lang="scss" scoped>
.title {
  font-size: 24px !important;
  line-height: 30px;
  font-weight: 700;
  font-family: "Montserrat", Helvetica, Arial, sans-serif;
  color: rgba(75, 85, 99, 1);
}
.premio-card {
  min-width: 392px;
  min-height: 70px;
  background: #005a97;
  color: white;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 16px;
  .icon {
    position: relative;
    top: 2px;
  }
  .text {
    padding: 10px;
    font-size: 16px;
    line-height: 24px;
    font-weight: 700;
    font-family: "Montserrat", Helvetica, Arial, sans-serif;
  }
  .value {
    display: flex;
    min-width: 143px;
    background: #00365b;
    min-height: 50px;
    border-radius: 4px;
    flex-direction: column;
    .title-value {
      font-weight: 500;
      font-size: 12px;
      line-height: 12px;
      font-family: "Montserrat", Helvetica, Arial, sans-serif;
      margin-bottom: 5px;
    }
    font-weight: 700;
    font-size: 18px;
    line-height: 18px;
    font-family: "Montserrat", Helvetica, Arial, sans-serif;
    padding: 8px 12px;
  }
}
</style>
